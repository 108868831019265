import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
import Environment from "./pages/Environment";
import Diversity from "./pages/Diversity";
import Rights from "./pages/Rights";
import Scroll from "./utils/Scroll";

export default function App() {
  return (
    <Router basename="/">
      <Scroll />
      <Route exact path="/" component={Home} />
      <Route path="/environment" component={Environment} />
      <Route path="/diversity" component={Diversity} />
      <Route path="/rights" component={Rights} />
    </Router>
  );
}