import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";

function Features() {
  return (
    <Jumbotron fluid className="event-wrapper bg-white mt-5 pt-5 mb-0 pb-0">
      <div className="event-description p-6">
        <h2 className="h1 condensed font-weight-normal">Twenty drivers drive the fastest race cars around the world. The drivers are all men, the race schedule demands a gas-guzzling circus of trucks and airplanes, and some Grand Prix take place in human rights hotspots.</h2>
      </div>
      <div className="event-media"></div>
      <div className="event-background"></div>
    </Jumbotron>
  );
}

export default Features;
