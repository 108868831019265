import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import Announcement from "../components/Announcement";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Table from "react-bootstrap/Table";
import { FiArrowUpRight } from "react-icons/fi";
import Jumbotron from "react-bootstrap/Jumbotron";

ReactGA.initialize("UA-104764221-11");
ReactGA.pageview(window.location.pathname + window.location.search);

function Rights() {
  return (
    <div>
      <Helmet>
        <title>Rights | The ethics of Formula 1</title>
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>

      <Announcement />
      <Navigation />

      <Container className="mt-5 pt-5">
        <Row className="mt-md-5 pt-md-5">
          <Col>
            <h1 className="display-0 condensed">
              <span>Human rights</span>
            </h1>
            <h1 className="pt-2 condensed font-weight-normal">We race as one?</h1>
          </Col>
        </Row>
      </Container>

      <Jumbotron fluid className="pt-5 bg-white mt-5 pb-0 mb-0">
        <Row className="h-100 p-0 m-0">
          <Col md={6} className="p-0 m-0">
            <img
              src="https://www.formula1.com/content/dam/fom-website/manual/Misc/WeRaceAsOne/MCL35_Rainbow_Social_1920X1080_V2.jpg.transform/9col/image.jpg"
              alt="f1"
              width="100%"
            />
          </Col>
          <Col md={6} className="p-0 m-0">
            <img
              src="https://e3.365dm.com/20/07/1600x900/skynews-lewis-hamilton-black-lives-matter_5031066.jpg?20200705151626"
              alt="f1"
              width="100%"
            />
          </Col>
        </Row>

        <Container>
          <p class="text-center pt-5 font-weight-normal">
            Teams and drivers show off their committment towards ending racism.
          </p>
        </Container>
      </Jumbotron>

      <Container className="mt-5 py-5">
        <Row className="mt-md-5 py-md-5">
          <Col md={6}>
            <p class="condensed h1 font-weight-normal">
              Shortly before the first race of the beleaguered 2020 season,
              Formula 1 adopted
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://www.formula1.com/en/latest/article.formula-1-launches-we-race-as-one-initiative.3s2AhNDApNDzrCoQDc1RY8.html"
                  target="_blank"
                >
                  #WeRaceAsOne
                  <FiArrowUpRight />
                </a>
              </span>
              in the fight against COVID-19 and global inequality. As tribute to
              George Floyd, many teams adopted various anti-racism motifs around
              their team apparel and equipment. Mercedes, whose cars were long
              known as the ‘Silver Arrows’ since the 1930s, introduced a
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://www.formula1.com/en/latest/article.breaking-news-mercedes-switch-to-all-black-livery-for-2020-in-stand-against.WLdoS6NGrJfExx8kqD5ZD.html"
                  target="_blank"
                >
                  new black livery
                  <FiArrowUpRight />
                </a>
              </span>
              for their car and race suits.
            </p>
            <br></br>
            <p>
              While Formula’s 1 commitment to inclusion, respect, and equality
              has been generally well received, the sport is criticized for
              turning a blind eye towards human rights controversies that
              implicate sponsors and race venues.
            </p>

            <p>
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://en.wikipedia.org/wiki/Sportswashing"
                  target="_blank"
                >
                  Sportswashing
                  <FiArrowUpRight />
                </a>
              </span>
              is the practice of hosting high-profile athletic events in
              countries with a poor reputation of protecting human rights. Such
              accusations have long been levied on Formula 1; in years past F1
              has been criticized for racing in Bahrain, Russia, and China.
              Recently, the announcement that Formula 1 would host the inaugural
              Saudi Arabian Grand Prix in 2021 drew criticism from{" "}
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://www.theguardian.com/sport/2020/nov/05/f1-confirm-saudi-arabia-2021-race-in-face-of-human-rights-criticism-formula-one-motor-sport"
                  target="_blank"
                >
                  Amnesty International and Human Rights Watch
                  <FiArrowUpRight />.
                </a>
              </span>
            </p>

            <p>
              Since Formula 1’s financial and venue decision making are closely
              linked, navigating the implications of selecting a country to host
              a race is a controversial activity. Any international sport will
              encounter the same obstacles. Formula 1 must do so while avoiding
              the slippery slope of rejecting all race venues based on
              historical misdeeds.
            </p>

            <p>
              In rare instances Formula 1 has shown clear resolve in the face of
              a social crisis, but generally only if there is overwhelming
              worldwide outcry. The
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://en.wikipedia.org/wiki/1985_South_African_Grand_Prix"
                  target="_blank"
                >
                  1985 South African Grand Prix
                  <FiArrowUpRight />
                </a>
              </span>
              was notable for being boycotted by some teams due to apartheid.
              The French government prohibited French teams Ligier and Renault
              from competing and a number of sponsors withdrew their branding
              from cars in preparation for the race. Shortly after the race,
              Formula 1 withdrew plans for planned races in South Africa due to
              the ongoing apartheid crisis.
            </p>

            <p>
              The troubling nature of Formula 1’s current positioning is that it
              must somehow unite its rallying cry of diversity—an ever-changing
              blend of cultures and backgrounds—with the unchanging and
              unyielding force of liberty. This is no simple task, and has beset
              even the Olympics on a number of occasions. But if Formula 1 is to
              continue with its stance of promoting social justice and equality,
              then some believe it ought to actively enforce its participation
              beyond a marketing campaign.
            </p>

            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScv38DJaLMeH5YsACH1dZUQaK965iSKqI1-azy7dEhTKRCfiw/viewform?embedded=true" width="100%" height="610px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default Rights;
