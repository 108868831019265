import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <Container>
      <Navbar
        collapseOnSelect
        expand="sm"
        bg="white"
        variant="light"
        className="px-0 mx-0 py-2 my-0"
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <Nav.Link>
              <Link className="nav-link-text" to="/">Home</Link>
            </Nav.Link>
            <Nav.Link>
              <Link className="nav-link-text" to="/environment">Environment</Link>
            </Nav.Link>
            <Nav.Link>
              <Link className="nav-link-text" to="/diversity">Diversity</Link>
            </Nav.Link>
            <Nav.Link>
              <Link className="nav-link-text" to="/rights">Human rights</Link>
            </Nav.Link>            
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

export default Navigation;
