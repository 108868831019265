import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import Announcement from "../components/Announcement";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Table from "react-bootstrap/Table";
import { FiArrowUpRight } from "react-icons/fi";
import Jumbotron from "react-bootstrap/Jumbotron";

ReactGA.initialize("UA-104764221-11");
ReactGA.pageview(window.location.pathname + window.location.search);

function Environment() {
  return (
    <div>
      <Helmet>
        <title>Environment | The ethics of Formula 1</title>
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>

      <Announcement />
      <Navigation />

      <Container className="mt-5 pt-5">
        <Row className="mt-md-5 pt-md-5">
          <Col>
            <h1 className="display-0 condensed">
              <span>Environment</span>
            </h1>
            <h1 className="pt-2 condensed font-weight-normal">
              Safeguarding the planet
            </h1>
          </Col>
        </Row>
      </Container>

      <Jumbotron fluid className="pt-5 bg-white mt-5 pb-0 mb-0">
        <Row className="h-100 p-0 m-0">
          <Col md={6} className="p-0 m-0">
            <img
              src="https://ferrari-cdn.thron.com/delivery/public/thumbnail/ferrari/fd649ed7-84a2-4dfe-9a68-d72125daf9d1/q076ls/std/1630x917/ferrari-f1-debut-1950-carouse?lcid=34785368-479f-476e-841c-48400bd788a3&v=15"
              alt="f1"
              width="100%"
            />
          </Col>
          <Col md={6} className="p-0 m-0">
            <img
              src="https://www.formula1.com/content/dam/fom-website/manual/Misc/2020/Car-launches/RedBull/GettyImages-1205730865.jpg.transform/12col-retina/image.jpg"
              alt="f1"
              width="100%"
            />
          </Col>
        </Row>

        <Container>
          <p class="text-center pt-5 font-weight-normal">
            The Ferrari 125 F1 of 1950 juxtaposed with the Red Bull RB16 of
            2020, representing an 300% increase in horsepower.
          </p>
        </Container>
      </Jumbotron>


      <Container className="mt-5 py-5">
        <Row className="mt-md-5 py-md-5">
          <Col md={6}>
            <p class="condensed h1 font-weight-normal">
              Controversy over the environmental impact of Formula 1 has grown
              within the past decade. 
              </p>
            <br></br>
            <p>
              The gradual electrification of ordinary
              road cars led F1 to mandate a new component of the powertrain: an
              electric battery that provides instant acceleration throughout the
              race and regenerates power from storing energy during braking.
              Since 2014, this electrical system dubbed
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://youtu.be/-yE3khtKZGg?t=12"
                  target="_blank"
                >
                  KERS
                  <FiArrowUpRight />
                </a>
              </span>
              has improved dramatically as teams found more mature ways of
              integrating and exploiting electrical energy. Such technology has
              improved the efficiency of the car and reduced its overall
              gasoline consumption.
            </p>
            
            <p>
              However, the main culprit behind F1’s massive carbon footprint are
              not the actual race cars, but the logistics vehicles used to
              transport teams and equipment across the different race venues
              across the world. Formula 1
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://www.espn.com/f1/story/_/id/28395224/the-environment-pose-f1-biggest-challenge-2020s"
                  target="_blank"
                >
                  estimated
                  <FiArrowUpRight />
                </a>
              </span>
              that only 0.7% of its 2018 carbon emissions came from the race
              cars themselves, with the main contributor being the trucks,
              airplanes, and cargo ships that circulate throughout four
              continents during the race season.
            </p>
            
            <p>
              There are also a number of solutions being proposed that would
              significantly cut Formula 1’s carbon footprint. Regardless of the
              method, F1 has{" "}
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://www.formula1.com/en/latest/article.formula-1-announces-plan-to-be-net-zero-carbon-by-2030.5IaX2AZHyy7jqxl6wra6CZ.html"
                  target="_blank"
                >
                  committed
                  <FiArrowUpRight />
                </a>
              </span>
              itself to being carbon-neutral by 2030. Some have criticized this
              plan as being too slow given the acceleration of climate change,
              while others have pointed out that carbon neutrality should not be
              used as a license to pollute.
            </p>
            
            <p>
              Some have argued that Formula 1 should transition fully to
              electric race cars, usurping the existing all-electric{" "}
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://www.fiaformulae.com/"
                  target="_blank"
                >
                  Formula-E
                  <FiArrowUpRight />
                </a>
              </span>
              racing series and embracing the next generation of automobile
              technology. Gradually, the logistical vehicles used to transport
              Formula 1 around the world would also shift to sustainable
              powertrains, but a transition beginning from the race cars would
              signal a clear and unmistakable message. This is a highly
              controversial stance, since the combustion engine is intimately
              associated with Formula 1’s 70-year racing history.
            </p>
            
            <p>
              However, any changes to the race cars are only addressing a small
              portion of the emissions produced by the sport, so an audience
              must bear in mind that the needed reduction of greenhouse gases
              from Formula 1 will be largely out of view.
            </p>
            
            <p>
              Ultimately, it is inevitable that Formula 1 will passively reduce
              its carbon footprint as ongoing efforts to improve the efficiency
              and sustainability of heavy engines, marine turbines, and jet
              engines are priorities for various companies around the world. But
              will it be too late?
            </p>

            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSftJtjTdDJ_jlNNXWVunGcb59xGJ9iowBbSi2oO8df-4ntRiQ/viewform?embedded=true" width="100%" height="830" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default Environment;
