import React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { FiArrowUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";

function Announcement() {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert
        variant="normal"
        className="announcement bg-danger text-white rounded-0 text-center p-0 pt-2 m-0"
        onClose={() => setShow(false)}
      >
        <Container>
          <Row>
            <Col className="text-left announcement-container" md={12}>
              <p className="small mb-2">
                <a
                  className="nav-link-text"
                  href="https://cmu.edu"
                  target="_blank"
                >
                  Presented by Eric Cheng for 82-288 at Carnegie Mellon
                  University <FiArrowUpRight />
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </Alert>
    );
  }
  return "";
}

export default Announcement;
