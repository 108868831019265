import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import Announcement from "../components/Announcement";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Table from "react-bootstrap/Table";
import { FiArrowUpRight } from "react-icons/fi";
import Jumbotron from "react-bootstrap/Jumbotron";
import Drivers from "../images/drivers.jpg";

ReactGA.initialize("UA-104764221-11");
ReactGA.pageview(window.location.pathname + window.location.search);

function Diversity() {
  return (
    <div>
      <Helmet>
        <title>Diversity | The ethics of Formula 1</title>
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>

      <Announcement />
      <Navigation />

      <Container className="mt-5 pt-5">
        <Row className="mt-md-5 pt-md-5">
          <Col>
            <h1 className="display-0 condensed">
              <span>Diversity</span>
            </h1>
            <h1 className="pt-2 condensed font-weight-normal">
              Making the sport more accessible
            </h1>
          </Col>
        </Row>
      </Container>

      <Jumbotron fluid className="pt-5 bg-white mt-5 pb-0 mb-0">
        <Row className="h-100 p-0 m-0">
          <Col md={12} className="p-0 m-0">
            <img src={Drivers} alt="f1" width="100%" />
          </Col>
        </Row>

        <Container>
          <p class="text-center pt-5 font-weight-normal">
            The 2020 driver lineup during winter testing.
          </p>
        </Container>
      </Jumbotron>

      <Container className="mt-5 py-5">
        <Row className="mt-md-5 py-md-5">
          <Col md={6} className="align-self-center">
            <p class="condensed h1 font-weight-normal">
              A female driver has never won a Drivers’ Championship. Only five
              women have ever raced in a Grand&nbsp;Prix. Overwhelmingly, men
              have formed the majority of racing drivers in the sport’s 70-year
              history.
            </p>
            <br></br>
            <p>
              The current drivers’ champion Lewis Hamilton is notable for being
              one of the most decorated drivers in the history of the sport, as
              well as the first and only black driver to ever compete.
            </p>

            <p>
              While various engineering records are broken by the sport
              annually, F1 remains a sport that perennially favors young
              European or American men from wealthy backgrounds. A number of
              celebrated drivers have hailed from Asia and South America, but
              over
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://en.wikipedia.org/wiki/List_of_Formula_One_drivers"
                  target="_blank"
                >
                  91% of all drivers
                  <FiArrowUpRight />
                </a>
              </span>
              to have ever competed have hailed from either the United States or
              Europe. This trend has not changed in the last two decades, except
              for American drivers becoming increasingly less common in exchange
              for Europeans.
            </p>

            <p>
              There are no inherent requirements, physical or geographical, that
              exclude anybody from competing in F1. The factors contributing to
              this dynamic are well understood yet weakly disrupted. There is a
              recursive diversity issue at every level of racing leading to
              Formula 1, which is exacerbated by the increasingly expensive cost
              of raising a racer. Formula 1 drivers earn their seats after
              graduating from successful junior careers in feeder series such as{" "}
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://www.fiaformula2.com/"
                  target="_blank"
                >
                  Formula 2
                  <FiArrowUpRight />
                </a>
              </span>
              , otherwise known as F2.
            </p>

            <p>
              Junior drivers earn their F2 seats after being selected from
              smaller, regional karting competitions. At the earliest stages of
              a young driver’s career, parents must prepare to spend six-figure
              sums annually to fund their children’s karting aspirations, which
              largely competes in Europe only. Most drivers in F1 have
              implicitly been training to become racing drivers for the majority
              of their lives. The reality of racing shows that young boys are
              placed in these formative karting careers more often than young
              girls, and the exclusivity of the sport to the wealthiest
              households limits the total sample size of young drivers.
            </p>

            <p>
              Today, there are strong forces encouraging people to participate
              in all athletic and hobbyist endeavors regardless of their
              background. It will take considerable time for the lagging effects
              of such rhetoric and marketing to manifest itself in Formula 1.
            </p>

            <p>
              An all-female racing championship called{" "}
              <span className="text-danger">
                {" "}
                <a
                  className="nav-link-text"
                  href="https://wseries.com/"
                  target="_blank"
                >
                  W Series
                  <FiArrowUpRight />
                </a>
              </span>
              was introduced in 2019 to encourage female participation in
              motorsport, but this has received various criticisms centered
              around the implication that females are segregated from entering
              Formula 1.
            </p>

            <p>
              Few will argue that the ultimate goal of diversity within Formula
              1 is to encourage teams to hire drivers and personnel. Some
              believe that the long-term solution to addressing diversity in
              motorsport is the same as many other industries and companies
              facing the same problem: promoting STEM participation to all
              children. By fostering an interest in mechanics and racing,
              traditionally European male dominated sports such as Formula 1 can
              find an enduring path ahead towards a more diverse grid.
            </p>

            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe6AJT6qQSOBOoTo4wGP0gQ9_lUye36W47blbZ4UyfPzvJ7jA/viewform?embedded=true" width="100%" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default Diversity;
