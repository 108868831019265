import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import { FiArrowUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";

function Hub() {
  return (
    <Jumbotron fluid className="bg-danger mb-0">
      <Container className="big-container text-white mr-0">
        <Row className="overflow-hidden text-nowrap">
          <Col md={12} xs={12}>
            <h1
              class="display-0-sm text-overflow condensed"
              data-ribbon="Controversy over the environmental impact of Formula 1 has grown
              within the past decade."
            >
              <Link className="nav-link-text" to="/environment">
                Environment
                <FiArrowUpRight />
              </Link>
            </h1>
          </Col>
          <Col md={12} xs={12}>
            <h1
              class="display-0-sm text-overflow condensed"
              data-ribbon="A female driver has never won a Drivers’ Championship. Only five women have ever raced in a Grand Prix. Overwhelmingly, men have formed the majority of racing drivers in the sport’s 70-year history."
            >
              <Link className="nav-link-text" to="/diversity">
                Diversity
                <FiArrowUpRight />
              </Link>
            </h1>
          </Col>
          <Col md={12} xs={12}>
            <h1
              class="display-0-sm text-overflow condensed"
              data-ribbon="Formula 1 adopted #WeRaceAsOne in the fight against COVID-19 and global inequality."
            >
              <Link className="nav-link-text" to="/rights">
                Human rights
                <FiArrowUpRight />
              </Link>
            </h1>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
}

export default Hub;
