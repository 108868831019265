import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import { FaGithub } from "react-icons/fa";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";

const Footer = () => {
  return (
    <Jumbotron fluid className="bg-black text-white m-0">
      <Container className="pb-5" id="contact">
        <hr class="hr-custom" />
        <Row className="pt-5">
          <Col md={10}>
            <h3 class="h1 condensed font-weight-normal">
              The ethics of Formula 1
            </h3>
          </Col>
          <Col sm={12} xs={12} md={2}>
            <h6 className="pl-0 font-weight-bold pb-2">
              Quick visit
            </h6>
            <Nav defaultActiveKey="/home" className="flex-column pb-5">
              <Nav.Link className="pl-0 text-white py-0" eventKey="link-1">
                <Link className="nav-link-text" to="/">
                  Home <FiArrowUpRight />
                </Link>
              </Nav.Link>
              <Nav.Link className="pl-0 text-white py-0" eventKey="link-1">
                <Link className="nav-link-text" to="/environment">
                  Environment <FiArrowUpRight />
                </Link>
              </Nav.Link>
              <Nav.Link className="pl-0 text-white py-0" eventKey="link-1">
                <Link className="nav-link-text" to="/diversity">
                  Diversity <FiArrowUpRight />
                </Link>
              </Nav.Link>
              <Nav.Link className="pl-0 text-white py-0" eventKey="link-1">
                <Link className="nav-link-text" to="/rights">
                  Human rights <FiArrowUpRight />
                </Link>
              </Nav.Link>                            
            </Nav>
          </Col>
        </Row>
        <hr class="hr-custom" />
        <Row className="mt-5">
          <Col md={6} sm={6} xs={6}>
            <small>Presented by Eric Cheng for 82-288 at Carnegie Mellon University</small>
          </Col>
          <Col className="text-right" md={6} sm={6} xs={6}>
            <Button
              variant="dark"
              href="https://chengeric.com"
              target="_blank"
              className="mr-1"
            >
              @
            </Button>
            <Button
              variant="dark"
              href="https://github.com/eh8"
              target="_blank"
              className="mr-1"
            >
              <FaGithub />
            </Button>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
};

export default Footer;
