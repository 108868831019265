import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import ReactGA from "react-ga";
import Announcement from "../components/Announcement";
import { Helmet } from "react-helmet";
import Features from "../components/Features";
import CTA from "../components/CTA";
import Hub from "../components/Hub";

ReactGA.initialize("UA-104764221-11");
ReactGA.pageview(window.location.pathname + window.location.search);

function Home() {
  return (
    <div>
      <Helmet>
        <title>Home | The ethics of Formula 1</title>
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>

      <Announcement />
      <Navigation />

      <Container className="my-5 py-5">
        <Row className="my-md-5 py-md-5">
          <Col>
            <h1 className="display-0 font-weight-bold condensed">
              The ethics of <span className="bg-pink text-danger">Formula&nbsp;1</span>
            </h1>
          </Col>
        </Row>
      </Container>

      <Features />

      <CTA />

      <Hub />

      <Footer />
    </div>
  );
}

export default Home;
