import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import FigureCaption from "react-bootstrap/FigureCaption";
import Figure from "react-bootstrap/Figure";

function CTA() {
  return (
    <>
      <Jumbotron fluid className="bg-black text-white pb-0 m-0">
        <Container className="pt-5">
          <Row>
            <Col md={12}>
              <h1 class="display-0-sm condensed pb-4">What is Formula 1?</h1>
              <p class="h1 condensed font-weight-normal">
                Formula 1 is class of competitions for the fastest single-seater
                race cars in the world. Over 400 million people watched the 2019
                season.
              </p>
            </Col>
          </Row>
          <Row className="py-5">
            <Col md={6}>
              <p class="h1 condensed font-weight-normal">
                Twenty drivers compete for points awarded based on finishing
                place. The driver with the most points wins the
                <span className="text-danger">
                  {" "}
                  World Drivers Championship.
                </span>
              </p>
            </Col>
            <Col md={6}>
              <p class="h1 condensed font-weight-normal">
                Ten teams earn points based on the sum of the finishing places
                of their two drivers. The team with the most points wins the
                <span className="text-danger">
                  {" "}
                  World Constructors Championship.
                </span>
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="align-items-center h-100">
            <Col lg={6} md={12} className="pt-0 pb-5">
              <p class="h1 condensed font-weight-normal">
                In the same eight seconds it takes a 2020 Honda Civic to reach
                60 mph, a modern Formula 1 racecar can accelerate to 186 mph.
              </p>
              <br></br>
              <p class="h1 condensed font-weight-normal">
                Drivers face upwards of 6g under braking and cornering, a force comparable to spacecraft reentering Earth's atmosphere.
              </p>
              <br></br>
              <p class="h1 condensed font-weight-normal">
                The 2020 drivers' champion is{" "}
                <span className="text-danger"> Lewis Hamilton 🇬🇧</span>, while
                his team{" "}
                <span className="text-danger"> Mercedes AMG Petronas 🇩🇪</span>{" "}
                are the 2020 constructors' champions.
              </p>
            </Col>
            <Col lg={6} md={12}>
              <Image
                src="https://www.mercedesamgf1.com/content/dam/brandhub/mercedes-amg-f1/wallpaper/M246640.jpg/jcr:content/renditions/cq5dam.web.1280.1280.jpeg"
                alt="f1"
                fluid
              />
              <Figure.Caption>
                Lewis Hamilton driving the Mercedes W11.
              </Figure.Caption>
            </Col>
          </Row>
        </Container>
        <Container className="py-5">
          <Row className="justify-content-center">
            <Col md={8}>
              <p class="h1 condensed font-weight-normal">
                A lot of good has come come out of Formula 1, but it still has a
                way to go.
              </p>
              <br></br>
              <p class="h1 condensed font-weight-normal">
                The sport is finding ways to secure a sustainable and more
                widely appealing future for itself while balancing the delicate
                financial instability brought on by COVID-19. It will need to
                find ways of rebranding itself as the forefront of{" "}
                <span className="text-danger">
                  environmental technology&nbsp;🌿
                </span>
                , a champion for{" "}
                <span className="text-danger">
                  diversity and inclusion&nbsp;♥
                </span>
                , and a morally unambiguous organization that upholds basic{" "}
                <span className="text-danger">
                  freedoms and liberties&nbsp;🕊
                </span>{" "}
                across the world.
              </p>
              <br></br>
              <p class="h1 condensed font-weight-normal">
                Explore the challenges Formula 1 is facing today and the
                discussion surrounding its future.
              </p>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </>
  );
}

export default CTA;
